import ReactDOM from 'react-dom';
import AsperaFileTransferCalc from './components/AsperaFileTransferCalc';
import { IntlProvider } from 'react-intl';
import normalizeWidgetInput from 'src/common/normalizeWidgetInput';
import widgetDefinition from './AsperaFileTransferCalc.widget';
import { RenderFn } from 'src/types/widgets';
import 'carbon-components/css/carbon-components.min.css';
import { Provider } from 'react-redux';
import store from '../aspera-file-transfer-calculator/lib/redux/store';
import { TranslationProvider } from '../../common/components/TranslationProvider/TranslationProvider';

const render: RenderFn = async function (instanceId, langCode, _origin, cb) {
  const { element, locale, messages } = await normalizeWidgetInput(
    instanceId,
    langCode,
    widgetDefinition,
  );
  if (!element || !locale || !messages) {
    return;
  }
  const localeCode = element.getAttribute('data-localecode') || 'en-us';

  ReactDOM.render(
    <Provider store={store}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        <TranslationProvider messages={messages}>
          <AsperaFileTransferCalc
            element={element}
            langCode={localeCode}
            test={false}
            countryToTest={'us'}
          />
        </TranslationProvider>
      </IntlProvider>
    </Provider>,
    element,
    () => cb(element),
  );
};

export default render;
